'use strict'
import React, { Component } from 'react'
import { render } from 'react-dom'

import { TrioCarousel } from './carousel'
import { TrioHorizontalScrollCarousel } from './horizontalScollCarousel'
var container = document.getElementById('react-carousel');

// render(
//     <TrioCarousel {...(container.dataset)} />,
//     container
//   )
render(
  
    <TrioHorizontalScrollCarousel {...(container.dataset)} />,
    container
  )