// HorizontalScrollCarousel.jsx
import React, { useMemo } from 'react';
const scrollbarHideStyles = `
  /* Hide scrollbar for Chrome, Safari and Opera */
  .overflow-x-auto::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .overflow-x-auto {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
`;

export const StyleWrapper = ({ children }) => (
  <>
    <style>{scrollbarHideStyles}</style>
    {children}
  </>
);

export const ImageGallery = ({image_info, indexImageInfo, position, setUseAutomaticScroll}) => {
    const {src, title, url_redirect, description, price, dimension, weight, other_images, video} = image_info;
    const [useAutomaticImageSwap, setUseAutomaticImageSwap] = React.useState(false);
    const [counterImageSwap, setCounterImageSwap] = React.useState(0);
    const imageRef = React.useRef(null);

    React.useEffect(() => {
        imageRef.current.addEventListener('mouseover', (e) => {
            setUseAutomaticScroll(false)
            setUseAutomaticImageSwap(true);
        });
    
        return () => {
            imageRef.current.removeEventListener('mouseover', updateHeight);
        };
    }, []);

    React.useEffect(() => {
        imageRef.current.addEventListener('mouseleave', (e) => {
            setUseAutomaticScroll(true)
            setUseAutomaticImageSwap(false);
        });

        return () => {
            imageRef.current.removeEventListener('mouseleave', updateHeight);
        };
    }, []);

    React.useEffect(() => {
        if (useAutomaticImageSwap) {
            const interval = setInterval(() => {
                setCounterImageSwap(counterImageSwap + 1);
            }, 500);
            return () => clearInterval(interval);
        }
    }, [useAutomaticImageSwap, counterImageSwap]);

    const allImages = useMemo( () => {
        return [src, ...other_images.map(image => image.src)];
    }, [src, other_images]);

    const srcImage = useMemo(() => {
        return allImages[counterImageSwap % allImages.length];
    }, [allImages, useAutomaticImageSwap, counterImageSwap])

    return (
        <>
            <img
                ref={imageRef}
                key={indexImageInfo}
                src={srcImage}
                title={title}
                alt="oeuvre céramique"
                className="flex-none object-contain h-[75%] snap-start my-auto mx-[2rem] rounded-[10px]"
                data-fancybox={`gallery-${position}-${indexImageInfo}`}
                data-product-title={title}
                data-product-description={description}
                data-product-price={price}
                data-url-redirect={url_redirect}
                data-product-dimension={dimension}
                data-product-weight={weight}
            />
            {other_images && other_images.map((image, index) => {
                return (
                    <img
                        key={index}
                        src={image.src}
                        title={image.title}
                        alt="oeuvre céramique"
                        className="hidden"
                        data-fancybox={`gallery-${position}-${indexImageInfo}`}
                        data-product-title={title}
                        data-product-description={description}
                        data-product-price={price}
                        data-product-dimension={dimension}
                        data-product-weight={weight}
                    />
                )
            })}
        </>
    )
}

export const HorizontalScrollCarousel = ({ listImageInfo, position, scrollDirection }) => {
    const [scrollInterval, setScrollInterval] = React.useState(null);
    const [useAutomaticScroll, setUseAutomaticScroll] = React.useState(true);
    const scrollRef = React.useRef(null);

    const widthCarousel = React.useMemo(() => {
        if (scrollRef.current) {
            return scrollRef.current.scrollWidth;
        }
    }, [scrollRef.current]);

    const handleInfiniteScroll = (widthCarousel) => {
        if (scrollRef.current) {
            const { scrollLeft, scrollWidth, clientWidth} = scrollRef.current;
            if (scrollDirection === 'left' && scrollLeft + clientWidth >= scrollWidth) {
                scrollRef.current.scrollLeft = 0;
            }
            
            // Détection si on atteint le début avec un seuil de tolérance
            if (scrollDirection === 'right' && scrollLeft - clientWidth <= -(scrollWidth - 1)) {
                scrollRef.current.scrollLeft = 0;
            }
        }
    };


    React.useEffect(() => {
        const interval = setInterval(() => {
            const { scrollLeft, scrollWidth, clientWidth} = scrollRef.current;
            if (!useAutomaticScroll) return

            if (scrollRef.current) {
                if (scrollDirection === 'left'){
                    scrollRef.current.scrollLeft += 1;
                }

                if (scrollDirection === 'right'){
                    scrollRef.current.scrollLeft += -1;
                }
                handleInfiniteScroll(widthCarousel);
            }
        }, 50);
        setScrollInterval(interval);
        return () => clearInterval(interval);
    }, [useAutomaticScroll]);

    const flexDirection = React.useMemo(() => {
        if (scrollDirection === 'left') {
            return 'flex-row';
        }
        if (scrollDirection === 'right') {
            return 'flex-row-reverse';
        }
    }, [scrollDirection])

    return (
      <div 
        ref={scrollRef} 
        className={`flex overflow-x-auto h-full flex-1 before:content-[''] after:content-[''] whitespace-nowrap scroll-snap-x snap-mandatory ${flexDirection}`}
      >
        {listImageInfo.map((image_info, indexImageInfo) => {
            return (
                <ImageGallery
                    image_info={image_info}
                    indexImageInfo={indexImageInfo}
                    position={position}
                    setUseAutomaticScroll={setUseAutomaticScroll}
                />
            )
        })}
      </div>
);
};

export const TrioHorizontalScrollCarousel = ({images_info, image_portrait}) => {
    const image_urls = JSON.parse(images_info);
    
    const [list_images_info_carousel_1, list_images_info_carousel_2, list_images_info_carousel_3] = image_urls.reduce(
        (acc, image_info, index) => {
            acc[index % 3].push(image_info);
            return acc;
        },
        [[], [], []]
    );

    return (
        <>
            <div class="w-4/5 m-auto">
                <StyleWrapper>
                    <div className="flex flex-col h-screen mt-[2rem]">
                        <div className="h-[33svh]">
                            <HorizontalScrollCarousel
                                listImageInfo={list_images_info_carousel_1}
                                position={"top"}
                                scrollDirection='left'
                            />
                        </div>
                        <div className="h-[33svh]">
                            <HorizontalScrollCarousel
                                listImageInfo={list_images_info_carousel_2}
                                position={"middle"}
                                scrollDirection='right'
                            />
                        </div>
                        <div className="h-[33svh]">
                            <HorizontalScrollCarousel
                                listImageInfo={list_images_info_carousel_3}
                                position={"bottom"}
                                scrollDirection='left'
                            />
                        </div>
                    </div>
                </StyleWrapper>

                <h1 class="text-center mb-[2rem] text-3xl">Démarche Artistique</h1>
                <div id="about-content" class="px-[2rem] mb-[2rem] flex-col sm:flex-row sm:flex-row-reverse flex items-center">
                    <div class="mb-[1rem] sm:ml-[5rem]">
                        <img class="rounded-xl" src={image_portrait} alt="Portrait de Le Thai Duong, entrain de peindre une sculpture" />
                    </div>
                    <div class="text-justify">
                        <p class="my-[0.5rem]">
                            Artiste peintre sculpteur. J’ai beaucoup travaillé dans les arts numériques ( peinture digitale et sculpture 3D).
                        </p>
                        <p class="my-[0.5rem]">
                            Je suis revenu plus récemment à la terre, pour des sensation plus authentiques et primaires.
                        </p>
                        <p class="my-[0.5rem]">
                            La pratique de la céramique émaillée me correspond pleinement . A l’argile ma pratique de la sculpture et l’émail mes aspirations de peintre.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};